<!--
 * @Descripttion: 绩效列表
 * @version: 
 * @Author: alex.yang
 * @Date: 2022-08-10 17:10:30
 * @LastEditors: alex.yang
 * @LastEditTime: 2022-12-02 09:35:43
-->
<template>
    <div class="performanceList">
        <div class="list-wrap">
            <div class="title-wrap" v-if="user.kf_level == 1">
                <div class="search-wrap">
                    <el-select clearable v-model="userId" filterable @change="selectChange" placeholder="请选择客服筛选">
                        <el-option
                            v-for="item in userOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                <el-button type="primary" @click="isShow = true">查询</el-button>
            </div>
            <div class="tag-wrap">
                <div class="item">
                    <div class="tag tag1"></div>
                    <div class="text">有使用绩效金</div>
                </div>
                <div class="item">
                    <div class="tag tag2"></div>
                    <div class="text">都有用绩效金</div>
                </div>
                <div class="item">
                    <div class="tag tag3"></div>
                    <div class="text">用得好绩效金</div>
                </div>
            </div>
            <div class="table-wrap">
                <el-table :data="tableData" border style="width: 100%" v-loading="loading">
                    <el-table-column fixed="left" align="center" prop="system_admin.nickname" label="客服名称"></el-table-column>
                    <el-table-column fixed="left" align="center" prop="year_month" label="年-月"></el-table-column>
                    <el-table-column align="center" prop="join_shop_count" label="评估门店"></el-table-column>
                    <el-table-column align="center" prop="join_shop_user_count" label="评估用户"></el-table-column>
                    <el-table-column align="center" prop="performance_1_total" label="奖励门店数" >
                        <template slot="header">
                            <span style="color:#409EFF">奖励门店数</span>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" prop="performance_1" label="有使用绩效金" >
                        <template slot="header">
                            <span style="color:#409EFF">有使用绩效金</span>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" prop="performance_2_total" label="奖励门店数">
                        <template slot="header">
                            <span style="color:#67C23A">奖励门店数</span>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" prop="performance_2_function_reward" label="功能使用奖励金额" width="150">
                        <template slot="header">
                            <span style="color:#67C23A">功能使用奖励金额</span>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" prop="performance_2" label="都有用绩效金">
                        <template slot="header">
                            <span style="color:#67C23A">都有用绩效金</span>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" prop="performance_3_total" label="奖励账号数">
                        <template slot="header">
                            <span style="color:#F56C6C">奖励账号数</span>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" prop="performance_3" label="用得好绩效金">
                        <template slot="header">
                            <span style="color:#F56C6C">用得好绩效金</span>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" prop="create_timex" label="生成时间" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column fixed="right" align="center" prop="result" label="总奖励金"></el-table-column>
                    <el-table-column fixed="right" align="center" label="操作" v-if="user.kf_level == 1" >
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" content="删除" placement="top">
                                <i @click="clickDel(scope.row)" class="iconfont el-icon-delete"></i>
                            </el-tooltip>
                    </template>
                    </el-table-column>
                </el-table>
                <div class="pagination-wrap">
                    <el-pagination
                        @current-change="handleCurrentChange"
                        :current-page.sync="currentPage"
                        :page-size="10"
                        layout="total, prev, pager, next"
                        :total="total">
                    </el-pagination>
                </div>
            </div>
        </div>
        <el-dialog title="查询上月绩效金"
            :before-close="close" 
            :close-on-click-modal="false"
            :visible.sync="isShow" 
            :custom-class="isShowGold?'gold-wrap':'dialog-wrap'">
            <div class="content-wrap" v-if="!isShowGold">
                <el-select clearable filterable v-model="user_id" @change="queryChange" placeholder="请选择客服查询">
                    <el-option
                        v-for="item in userOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
                <div class="brand-wrap" v-loading="checkLoading" v-if="user_id != ''">
                    <div style="margin: 15px 0;"></div>
                    <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
                    <div style="margin: 15px 0;"></div>
                    <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">
                        <el-checkbox v-for="item in cities" :label="item" :key="item.ep_id">
                            {{item.name}}
                        </el-checkbox>
                    </el-checkbox-group>
                </div>
                <div class="btn-wrap">
                    <el-button type="primary" @click="clickQuery">查询</el-button>
                </div>
            </div>
            <div class="performance-gold" v-else>
                <div class="item">
                    <div class="title-wrap">
                        <div class="tag"></div>
                        <div class="title">有使用绩效金</div>
                    </div>
                    <el-table :data="viscosityData" border style="width: 100%" v-loading="loading1">
                        <el-table-column align="center" prop="join_shop_count" label="评估门店" ></el-table-column>
                        <el-table-column align="center" prop="join_shop_user_count" label="评估用户"></el-table-column>
                        <el-table-column align="center" prop="performance_1_total" label="奖励门店数"></el-table-column>
                        <el-table-column align="center" prop="performance_1" label="获得奖励金"></el-table-column>
                    </el-table>
                </div>
                <div class="item">
                    <div class="title-wrap">
                        <div class="tag"></div>
                        <div class="title">都有用绩效金</div>
                    </div>
                    <el-table :data="funData" border style="width: 100%" v-loading="loading2">
                        <el-table-column align="center" prop="join_shop_count" label="评估门店"></el-table-column>
                        <el-table-column align="center" prop="join_shop_user_count" label="评估用户"></el-table-column>
                        <el-table-column align="center" prop="performance_2_total" label="奖励门店数"></el-table-column>
                        <el-table-column align="center" prop="performance_2_function_reward" label="功能使用奖励金额"></el-table-column>
                        <el-table-column align="center" prop="performance_2" label="获得奖励金"></el-table-column>
                    </el-table>
                </div>
                <div class="item">
                    <div class="title-wrap">
                        <div class="tag"></div>
                        <div class="title">用得好绩效金</div>
                    </div>
                    <el-table :data="newData" border style="width: 100%" v-loading="loading3">
                        <el-table-column align="center" prop="join_shop_count" label="评估门店"></el-table-column>
                        <el-table-column align="center" prop="join_shop_user_count" label="评估用户"></el-table-column>
                        <el-table-column align="center" prop="performance_3_total" label="奖励账号数"></el-table-column>
                        <el-table-column align="center" prop="performance_3" label="获得奖励金"></el-table-column>
                    </el-table>
                </div>
                <div class="result">总奖励金：{{result}} 元</div>
                <div class="btn-wrap" v-if="!loading2">
                    <el-button :loading="btnLoading" type="primary" @click="recordsGenerated">生成记录</el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import pageMixin from '@/internal/pageMixin'
import common from "@/utils/common.js"
export default {
    mixins:[pageMixin],
    data () {
        return {
            pageMixin_index:'servicePerformance',
            userOptions: [],
            userId: '',
            user_id: '',
            currentPage: 1,
            total: 0,
            loading: false,
            tableData: [],
            isShow: false,
            isShowGold: false,
            viscosityData: [],
            funData: [],
            newData: [],
            loading1: false,
            loading2: false,
            loading3: false,
            btnLoading: false,
            result: 0,
            user: {},

            checkAll: false,
            checkedCities: [],
            cities: [],
            isIndeterminate: true,
            checkLoading: false
        }
    },
    mounted () {
        this.user = JSON.parse(localStorage.getItem('userInfo'));
        this.getPerformanceLog();
        this.getUserConditions();
    },
    methods: {
        // 全选
        handleCheckAllChange(val) {
            this.checkedCities = val ? this.cities : [];
            this.isIndeterminate = false;
        },
        // 选择
        handleCheckedCitiesChange(value) {
            let checkedCount = value.length;
            this.checkAll = checkedCount === this.cities.length;
            this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length;
        },
        // 获取品牌数据
        getEpList(){
            let params = {
                user_id: this.user_id,
            }
            this.checkLoading = true;
            common.connect("/customerservicev1/enterprise_brand/EpConditions",params,(res)=>{
                this.cities = res.data;
                this.checkedCities = res.data;
                this.checkLoading = false;
            });
        },
        // 删除
        clickDel(row){  
            this.$confirm('此操作将永久删除该条绩效, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                common.connect("/customerservicev1/data_report/deletePerformanceLogV3",{id: row.id},(res)=>{
                    this.$message({
                        message: res.msg,
                        type: 'success'
                    });
                    this.getPerformanceLog();
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
        },
        // 生成记录
        recordsGenerated(){
            let params = {
                system_admin_id: this.user_id,
                join_shop_count: this.viscosityData[0].join_shop_count,
                join_shop_user_count: this.viscosityData[0].join_shop_user_count,
                performance_1: this.viscosityData[0].performance_1,
                performance_2: this.funData[0].performance_2,
                performance_3: this.newData[0].performance_3,
                performance_1_total: this.viscosityData[0].performance_1_total,
                performance_2_total: this.funData[0].performance_2_total,
                performance_3_total: this.newData[0].performance_3_total,
                performance_2_function_reward: this.funData[0].performance_2_function_reward,
                result: this.result,
            }
            this.btnLoading = true;
            common.connect("/customerservicev1/data_report/storePerformanceLogV3",params,(res)=>{
                if(res.isSuccess == 1){
                    this.user_id = '';
                    this.isShowGold = false;
                    this.isShow = false;
                    this.getPerformanceLog();
                    this.$message.success('生成记录成功！')
                }
                this.btnLoading = false;
            });
        },
        // 选择客服
        clickQuery(){
            if(this.user_id == ''){
                this.$message.error("请选择查询的客服");
                return;
            }
            if(this.checkedCities.length<1){
                this.$message.error("请选择品牌");
                return;
            }
            this.result = 0;
            this.isShowGold = true;
            this.getPayment1();
            this.getPayment2();
            this.getPayment3();
        },
        // 获取有使用绩效金列表数据
        getPayment1(){
            let list = [];
            this.checkedCities.forEach(v=>{
                list.push(v.ep_id)
            })
            let params = {
                user_id: this.user_id,
                ep_ids: "["+list+"]"
            }
            this.loading1 = true;
            this.viscosityData = [];
            common.connect("/customerservicev1/data_report/performanceT1V3",params,(res)=>{
                this.viscosityData.push(res.data);
                this.loading1 = false;
                let result = 
                Number(this.viscosityData[0].performance_1) +
                Number(this.funData[0].performance_2) +
                Number(this.newData[0].performance_3)
                this.$set(this,'result',result);
            });
        },
        // 获取功能列表数据
        getPayment2(){
            let list = [];
            this.checkedCities.forEach(v=>{
                list.push(v.ep_id)
            })
            let params = {
                user_id: this.user_id,
                ep_ids: "["+list+"]"
            }
            this.loading2 = true;
            this.funData = [];
            common.connect("/customerservicev1/data_report/performanceT2V3",params,(res)=>{
                this.funData.push(res.data);
                this.loading2 = false;
                let result = 
                Number(this.viscosityData[0].performance_1) +
                Number(this.funData[0].performance_2) +
                Number(this.newData[0].performance_3)
                this.$set(this,'result',result);
            });
        },
        // 获取新门店列表数据
        getPayment3(){
            let list = [];
            this.checkedCities.forEach(v=>{
                list.push(v.ep_id)
            })
            let params = {
                user_id: this.user_id,
                ep_ids: "["+list+"]"
            }
            this.loading3 = true;
            this.newData = [];
            common.connect("/customerservicev1/data_report/performanceT3V3",params,(res)=>{
                this.newData.push(res.data);
                this.loading3 = false;
                let result = 
                Number(this.viscosityData[0].performance_1) +
                Number(this.funData[0].performance_2) +
                Number(this.newData[0].performance_3)
                this.$set(this,'result',result);
            });
        },
        // 获取列表数据
        getPerformanceLog(){
            let params = {
                user_id: this.userId,
                page: this.currentPage
            }
            this.loading = true;
            common.connect("/customerservicev1/data_report/performanceLogV3",params,(res)=>{
                this.tableData = res.data.list;
                this.total = res.data.count;
                this.loading = false;
            });
        },
        // 获取用户数据
        getUserConditions(){
            common.connect("/customerservicev1/admin/userConditions",{},(res)=>{
                let resultSecond =  res.data.map(v =>{
                return{
                    value: v.user_id,
                    label: v.nickname
                }
                })
                this.userOptions = resultSecond;
            });
        },
        // 选择客服查询
        queryChange(){
            this.getEpList();
        },
        selectChange(){
            this.currentPage = 1;
            this.getPerformanceLog();
        },
        close(){
            this.user_id = '';
            this.isShowGold = false;
            this.isShow = false;
        },
        handleCurrentChange(val){
            this.currentPage = val;
            this.getPerformanceLog();
        }
    },
}
</script>

<style lang='scss'>
.performanceList{
    .list-wrap{
        .title-wrap{
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        .tag-wrap{
            display: flex;
            justify-content: flex-end;
            align-items: center;
            .item{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                margin-left: 20px;
                .tag{
                    width: 13px;
                    height: 13px;
                    border-radius: 100%;
                    margin-right: 5px;
                }
                .tag1{
                    background: #409EFF;
                }
                .tag2{
                    background: #67C23A;
                }
                .tag3{
                    background: #F56C6C;
                }
                .text{
                    font-size: 14px;
                    color: #333;
                }
            }
        }
        .table-wrap{
            padding-top: 10px;
            .pagination-wrap{
                display: flex;
                justify-content: flex-end;
                align-items: center;
                padding-top: 20px;
            }
        }
    }
    .dialog-wrap{
        width: 550px;
        .content-wrap{
            margin: 10px 20px;
            .el-select{
                width: 100%;
            }
            .btn-wrap{
                width: 100%;
                display: flex;
                justify-content: center;
                padding-top: 30px;
            }
        }
    }
    .gold-wrap{
        width: 1000px;
        .performance-gold{
            margin: 0px 20px;
            .item{
                padding-bottom: 10px;
                .title-wrap{
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    padding-bottom: 10px;
                    .tag{
                        width: 4px;
                        height: 15px;
                        background: #409EFF;
                        border-radius: 10px;
                    }
                    .title{
                        font-size: 14px;
                        font-weight: bold;
                        padding-left: 10px;
                        color: #000;
                    }
                }
            }
            .result{
                font-size: 16px;
                color: #000;
                font-weight: bold;
                text-align: right;
                padding: 10px 0;
            }
            .btn-wrap{
                display: flex;
                justify-content: center;
                align-items: center;
                padding-bottom: 15px;
            }
        }
    }
    .el-dialog{
        margin: 0;
        margin-top: 0vh !important;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        padding-bottom: 10px;
    }
    .el-checkbox-group{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: 100%;
        .el-checkbox{
            width: 200px;
            line-height: 30px;
            overflow: hidden;
        }
    }
}
</style>
