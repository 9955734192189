<!--
 * @Descripttion: 
 * @version: 
 * @Author: alex.yang
 * @Date: 2022-08-10 17:11:04
 * @LastEditors: alex.yang
 * @LastEditTime: 2022-11-30 08:56:19
-->
<template>
    <div class="performanceExplain">
        <div class="content">
            <h3>一、有使用</h3>
            <p>门店中，单个账号使用总次数达3次及以上，门店评为实际有使用门店，实际有使用门店奖励2元/门店；</p>
            <h3>二、都有用</h3>
            <p>1、评估内容宣传，门店在使用名片、产品海报、共享圈、云店内容，以及线下扫码方式进入云店，带来访客增长视为内容宣传类功能有使用，评定标准，门店独立访客量不低于10/月；奖励3元/门店；</p>
            <p>2、评估工具使用，客户管理、全景、备忘、报价功能使用，门店使用功能满足单账号不低于3次/月（不计门店全部账号达到此标准），记为该门店该月实际使用该功能，每项功能奖励0.5元/门店，共计4项，工具使用总奖励2元/门店；</p>
            <h3>三、用得好</h3>
            <p>门店高频账号奖励，（每月新增高频账号奖励10元，不包含已满足高频并给与奖励的账号；不包含高频又调到低粘度又重回高频的账号）；</p>
            <h3>四、考核项</h3>
            <p>1、每月有使用增长率</p>
            <p>2、功能使用增长变化</p>
            <p>3、高频用户增长率</p>
        </div>
    </div>
</template>

<script>
export default {
    data () {
        return {

        }
    },
    mounted () {

    },
    methods: {

    },
}
</script>

<style lang='scss'>
.performanceExplain{
    .content{
        p{
            line-height: 25px;
            text-align: justify;
        }
    }
}
</style>
